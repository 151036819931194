import { tv } from 'tailwind-variants'

export const divider = {
  alpha: tv({
    base: 'border-t border-l border-brand-gray-300',
  }),
  bravo: tv({
    base: 'border-t border-l border-brand-gray-300',
  }),
  charlie: tv({
    base: 'border-t border-l border-brand-gray-300',
  }),
}
