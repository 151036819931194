<script setup lang="ts">
import { divider } from './dividerStyles'
const theme = useRuntimeConfig().public.theme as keyof typeof divider

const style = divider[theme]()
</script>

<template>
  <div :class="style" />
</template>
